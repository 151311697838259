<template>
  <svg
    width="70%"
    height="70%"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.4939 50.1906C45.3524 50.1906 45.2108 50.1434 45.1163 50.0489L35.6305 43.2531C23.0604 47.8985 10.3818 38.637 10.3818 25.8385C10.3818 15.5974 18.7351 7.24414 28.9761 7.24414C39.2643 7.24414 47.5705 15.5974 47.5705 25.8385C47.5705 29.7084 46.3905 33.4368 44.1725 36.5515L46.1546 49.3882C46.2215 49.8051 45.8102 50.1906 45.4939 50.1906ZM36.1967 41.9788L44.5973 48.0196L42.8511 36.4572C42.8511 36.174 42.9927 35.9853 43.087 35.8908C45.1636 32.9647 46.2962 29.5196 46.2962 25.8386C46.2962 16.3526 38.5566 8.61282 29.0233 8.61282C19.5373 8.61282 11.7974 16.3525 11.7974 25.8386C11.7974 35.3717 19.5372 43.1116 29.0233 43.1116C31.147 43.1116 33.2706 42.734 35.3 41.9317C35.7248 41.6957 36.0552 41.8845 36.1967 41.9788Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.8468 26.4997C53.4692 26.4997 53.1861 26.2166 53.1861 25.839C53.1861 12.5304 42.3316 1.67578 28.9757 1.67578C15.6671 1.67578 4.81248 12.5304 4.81248 25.839C4.81248 26.7149 3.49121 26.7149 3.49121 25.839C3.49121 11.7752 14.9593 0.307129 29.023 0.307129C43.0868 0.307129 54.5549 11.7752 54.5549 25.839C54.5077 26.2166 54.2244 26.4997 53.8468 26.4997Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.847 42.7339H50.4019C50.0243 42.7339 49.7412 42.4507 49.7412 42.0732V25.8384C49.7412 25.4609 50.0243 25.1777 50.4019 25.1777H53.847C56.1123 25.1777 58.0001 27.0183 58.0001 29.3309V38.6279C58.0001 40.8461 56.1124 42.7339 53.847 42.7339ZM51.0626 41.3652H53.847C55.4044 41.3652 56.6315 40.1381 56.6315 38.5807V29.3309C56.6315 27.7735 55.4044 26.5464 53.847 26.5464H51.0626V41.3652Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.59675 42.7339H4.15168C1.88632 42.7339 -0.00146484 40.8933 -0.00146484 38.5807V29.3309C-0.00146484 27.0655 1.83908 25.1777 4.15168 25.1777H7.59675C7.97433 25.1777 8.25745 25.4609 8.25745 25.8384V42.0259C8.30455 42.4035 7.97433 42.7339 7.59675 42.7339ZM4.15168 26.4991C2.59426 26.4991 1.36718 27.7261 1.36718 29.2835V38.5807C1.36718 40.1381 2.59426 41.3652 4.15168 41.3652H6.93592V26.4991L4.15168 26.4991Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.0674 54.3907C24.0203 54.3907 23.9731 54.3907 23.9259 54.3907C16.894 52.9748 10.7115 48.6331 7.03044 42.4035C6.56428 41.6012 7.76567 40.9312 8.21028 41.6954C11.7026 47.5946 17.5547 51.7478 24.209 53.0692C25.0411 53.2556 24.8279 54.3907 24.0674 54.3907Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.1459 57.6953H26.8041C24.9163 57.6953 23.3589 56.1379 23.3589 54.2503C23.3589 52.3152 24.9163 50.7578 26.8041 50.7578H31.1459C33.0337 50.7578 34.5911 52.3152 34.5911 54.2503C34.6382 56.138 33.0808 57.6953 31.1459 57.6953ZM26.8041 52.1265C25.6242 52.1265 24.6803 53.0704 24.6803 54.2503C24.6803 55.4301 25.6242 56.3741 26.8041 56.3741L31.1459 56.3739C32.3257 56.3739 33.2697 55.4301 33.2697 54.2503C33.2697 53.0703 32.3257 52.1265 31.1459 52.1265H26.8041Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.8666 29.1897C17.7429 29.1897 16.0439 27.4909 16.0439 25.3671C16.0439 23.2433 17.7429 21.5444 19.8666 21.5444C21.9432 21.5444 23.6894 23.2433 23.6894 25.3671C23.6894 27.4907 21.9902 29.1897 19.8666 29.1897ZM19.8666 22.913C18.498 22.913 17.3653 24.0457 17.3653 25.4142C17.3653 26.7829 18.4981 27.8683 19.8666 27.8683C21.2352 27.8683 22.3207 26.7829 22.3207 25.4142C22.3207 23.9984 21.2352 22.913 19.8666 22.913Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.975 29.1897C26.8985 29.1897 25.1523 27.4909 25.1523 25.3671C25.1523 23.2433 26.8512 21.5444 28.975 21.5444C31.0988 21.5444 32.7976 23.2433 32.7976 25.3671C32.7976 27.4907 31.0986 29.1897 28.975 29.1897ZM28.975 22.913C27.6063 22.913 26.521 23.9984 26.521 25.4142C26.521 26.7829 27.6063 27.8683 28.975 27.8683C30.3436 27.8683 31.4762 26.7829 31.4762 25.4142C31.4762 24.0455 30.3436 22.913 28.975 22.913Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.1317 29.1897C36.0081 29.1897 34.3091 27.4909 34.3091 25.3671C34.3091 23.2433 36.0081 21.5444 38.1317 21.5444C40.2083 21.5444 41.9545 23.2433 41.9545 25.3671C41.9073 27.4907 40.2084 29.1897 38.1317 29.1897ZM38.1317 22.913C36.7631 22.913 35.6306 24.0457 35.6306 25.4142C35.6306 26.7829 36.7632 27.8683 38.1317 27.8683C39.5004 27.8683 40.5859 26.7829 40.5859 25.4142C40.5859 23.9984 39.5004 22.913 38.1317 22.913Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style></style>
